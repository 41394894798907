.c3-axis-y text {
  font-size: 15px;
}

.c3-axis-x text {
  font-size: 13px;
}

.c3-axis-y-label {
  transform-origin: -6px 0px;
}

.c3-xgrid-line line {
  stroke: #444444
}

.c3-ygrid-line.max line {
  stroke: #b3011e
}

.c3-ygrid-line.min line {
  stroke: green
}

html,
body {
  height: 100%;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

#disclaimerLink:hover {
  color: #222222
}